$(document).ready(function() {

$('.hamburger').on('click', function() {
	$(this).toggleClass('hamburger--active');
	$('.nav').toggleClass('js-nav-show');
});

//hide header when scrolling down
var $currScroll = $(window).scrollTop(),
	$lastScroll = $currScroll;

$(window).on('scroll', function() {
	var $headerHight = $('.header').outerHeight();
	$currScroll = $(window).scrollTop();

	if($currScroll > $headerHight / 3) {
		$('.header').addClass('header-hiden');
	} else if ($currScroll - $lastScroll < +100) {
		$('.header').removeClass('header-hiden');
	}

	$lastScroll = $currScroll;
});

var $plus = $('.box-oferta__title__plus'),
	$title = $('.box-oferta__title');


$title.on('click', function() {
	if($(this).parents('.box-oferta').hasClass('box-oferta--active')) {
		$(this).parents('.box-oferta').removeClass('box-oferta--active');
	} else {
		$(this).parents('.box-oferta').addClass('box-oferta--active');
	}
});


//choose pricelist 
var $priceList = $('.cennik__list__choose > li'),
	$price = $('.cennik__price');

$priceList.on('click', function() {
	var $dataPrice = $(this).data('price'),
		$content = $('.cennik__price[data-price="' + $dataPrice + '"]');

	$('.cennik__list__choose > li').removeClass('price-active');
	$(this).addClass('price-active');

	$price.removeClass('cennik__price--active');
	$content.addClass('cennik__price--active');
});



// choose priceList on mobile (select)
if($(window).width() < 768) {
var $select = $('.cennik__list__choose'),
	$mobileChoose = $('.cennik__list__mobileChoose');

	$priceList.on('click', function() {
		$select.removeClass('js-cennik__list__choose-show');
		var target = $(this).text();
		$mobileChoose.html(target);
		$mobileChoose.removeClass('js-mobileChoose-active');
	});

	$mobileChoose.on('click', function(e) {
		$(this).toggleClass('js-mobileChoose-active');
		$(this).toggleClass('js-cennik__list__choose-show');
			$select.toggleClass('js-cennik__list__choose-show');
			// e.stopImmediatePropagation();
			var target = $(this).next('ul');
			console.log('target to: ' + target);
			var currentPriceList = $(this).text();
			
		});

}






//popup lightbox
var $certyficate = jQuery('.certifications__certification'),
	$certyficatePopup = jQuery('.certifications__popup');

$certyficate.on('click', function() {
	var link = $(this).find('img').attr('src');

	console.log("link do zdjecia to " + link);

	$certyficatePopup.addClass('popup-show');
	$('.overlay').addClass('js-overlay-show');
	$certyficatePopup.find('img').attr('src', link);
});

$('.certifications__popup__close, .overlay').on('click', function() {
	$certyficatePopup.removeClass('popup-show');
	$('.overlay').removeClass('js-overlay-show');
});




}); // the end document ready



// //google maps
// function initMap() {
//     var center = {lat: 50.8699627, lng: 20.6330355};
//     var map = new google.maps.Map(document.getElementById('map'), {
//       zoom: 16,
//       center: center,
//       scrollwheel: false
//     });
//     var marker = new google.maps.Marker({
//       position: {lat: 50.8693242, lng: 20.633024},
//       map: map
//     });
//   }




